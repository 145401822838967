import Swiper from 'swiper';
import { Scrollbar, Pagination, EffectFade, Autoplay, Controller, Navigation } from 'swiper/modules';
import { Fancybox } from "@fancyapps/ui";
FontAwesomeConfig = { searchPseudoElements: true };
import '@fortawesome/fontawesome-free/js/fontawesome';
import '@fortawesome/fontawesome-free/js/solid';
import '@fortawesome/fontawesome-free/js/brands';

const mastheadImageSlider = new Swiper('.masthead__image-slider', { 
    modules: [Scrollbar, Controller],
    slidesPerView: 1,
    spaceBetween: 0,
    speed: 1000,

    scrollbar: {
        el: '.masthead__image-slider-scrollbar',
        hide: false,
        draggable: true,
    },

    autoplay: {
        delay: 9000,
    }, 
});

const productMainSlider = new Swiper('.woocommerce-product-gallery__slider .swiper', {
    modules: [Pagination],
    slidesPerView: 1,
    loop: false,
    spaceBetween: 30,
    // autoHeight: true,

    pagination: {
        el: ".swiper-pagination",
        clickable: true,
    },
});

const subcatsSlider = new Swiper('.subcats__slider', {
    modules: [Scrollbar],
    slidesPerView: 'auto',
    spaceBetween: 20,

    scrollbar: {
        el: '.subcats__slider-scrollbar',
        hide: false,
        draggable: true,
    },
});

const testimonialsSlider = new Swiper('.interior-testimonials__slider', {
    modules: [Scrollbar],
    slidesPerView: 1,
    spaceBetween: 20,
    // speed: 1000,

    scrollbar: {
        el: '.interior-testimonials__slider-scrollbar',
        hide: false,
        draggable: true,
    },

    autoplay: {
        delay: 9000,
    }, 
});

function initializeHpFavouritesSlider() {
    return new Swiper('.hp-favourites__slider', {
        modules: [Scrollbar, Navigation],
        slidesPerView: 4,
        spaceBetween: 60,
        speed: 600,
        scrollbar: {
            el: '.hp-favourites__slider-scrollbar',
            hide: false,
            draggable: true,
        },

        navigation: {
            nextEl: ".hp-favourites__slider__nav--next",
            prevEl: ".hp-favourites__slider__nav--prev",
        },

        breakpoints: {
            0: {
                slidesPerView: 1,
                spaceBetween: 30,
            },
            481: {
                slidesPerView: 2,
                spaceBetween: 40,
            },
            768: {
                slidesPerView: 3,
                spaceBetween: 40,
            },
            1024: {
                slidesPerView: 4,
                spaceBetween: 60,
            }
        }
    });
}

function initializeHpRoomsSlider() {
    return new Swiper('.hp-rooms__slider', {
        modules: [Scrollbar],
        slidesPerView: 1,
        spaceBetween: 30,
        speed: 600,

        scrollbar: {
            el: '.hp-rooms__slider-scrollbar',
            hide: false,
            draggable: true,
        },

        breakpoints: {
            650: {
                slidesPerView: 2,
                spaceBetween: 20,
            },
            768: {
                slidesPerView: 2,
                spaceBetween: 30,
            },
        }
    });
}

let hpFavouritesSlider;
let hpRoomsSlider;

function checkSliders() {
    if (window.innerWidth < 481) {
        if (hpFavouritesSlider) {
            hpFavouritesSlider.destroy(true, true);
            hpFavouritesSlider = null;
        }
        if (hpRoomsSlider) {
            hpRoomsSlider.destroy(true, true);
            hpRoomsSlider = null;
        }
    } else {
        if (!hpFavouritesSlider) {
            hpFavouritesSlider = initializeHpFavouritesSlider();
        }
        if (!hpRoomsSlider) {
            hpRoomsSlider = initializeHpRoomsSlider();
        }
    }
}

document.addEventListener('DOMContentLoaded', function() {
    checkSliders();
    window.addEventListener('resize', checkSliders);
});

Fancybox.bind('[data-fancybox="gallery"]', {});

jQuery(document).ready(function($) {
    $('.mega-hover > a').on('click', function(event) {
        event.preventDefault();
    });

    $('.mega-menu .menu-item-has-children > a').on('click', function(event) {
        if ($('header.header').hasClass('nav--nav-open')) {
            event.preventDefault(); // Prevent default action for parent menu item
            $('.sub-menu').not($(this).next('.sub-menu')).slideUp();
            $(this).next('.sub-menu').slideToggle();
        }
    });

    $('.mega-menu__brands > h4').on('click', function(event) {
        if ($('header.header').hasClass('nav--nav-open')) {
            event.preventDefault();
            $('.sub-menu').not($(this).next('.sub-menu')).slideUp();
            $('.mega-menu__brands .sub-menu').slideToggle();
        }
    });
});

jQuery(document).ready(function($) {
    $('.mega-menu a').each(function() {
        var link = $(this);

        // Check if the <a> tag is empty (no text or HTML content)
        if(link.text().trim() === '' && link.children().length === 0) {
            // Hide the empty <a> tag
            link.hide();
        }
    });

    $('.mega-hover').on('click', function(e) {
        e.stopPropagation();
        $('.mega-menu').toggleClass('active');
    })

    // $('#mega-menu-back').on('click', function() {
    //     $('.mega-menu').removeClass('active');
    // });

    $('.wcpa_image input[type="checkbox"]').on('change', function() {
        if ($(this).is(':checked')) {
            $(this).closest('.wcpa_image').addClass('active');
        } else {
            $(this).closest('.wcpa_image').removeClass('active');
        }
    });
});



// function debounce(func, wait, immediate) {
//     var timeout;
//     return function() {
//         var context = this, args = arguments;
//         var later = function() {
//             timeout = null;
//             if (!immediate) func.apply(context, args);
//         };
//         var callNow = immediate && !timeout;
//         clearTimeout(timeout);
//         timeout = setTimeout(later, wait);
//         if (callNow) func.apply(context, args);
//     };
// }

// var headerBottom = document.querySelector('.header__bottom');
// var stickyOffset = headerBottom.offsetTop;

// function isPageTallEnough() {
//    return document.body.offsetHeight > window.innerHeight + 200; // Adjusted threshold
// }

// function stickyHeader() {
//     console.log("PageYOffset: ", window.pageYOffset, "StickyOffset: ", stickyOffset);
//     if (!isPageTallEnough()) {
//         headerBottom.classList.remove("sticky");
//         return;
//     }

//     if (window.pageYOffset >= stickyOffset) {
//         headerBottom.classList.add("sticky");
//     } else {
//         headerBottom.classList.remove("sticky");
//     }
// }


// window.addEventListener('resize', debounce(function() {
//     stickyOffset = headerBottom.offsetTop; // Recalculate stickyOffset
//     stickyHeader(); // Reapply logic based on new conditions
// }, 150));

// window.onload = function() {
//     stickyOffset = headerBottom.offsetTop;
//     stickyHeader();
// };

// window.onscroll = function() {
//     stickyHeader();
// };




// /* DIALOG ELEMENT */
// document.addEventListener('DOMContentLoaded', function() {

//     const openProductButtons = document.querySelectorAll('.product-expand');
//     let currentOpenDialog = null;

//     // Event listener to open the dialog
//     openProductButtons.forEach(button => {
//         button.addEventListener('click', (event) => {
//             event.stopPropagation();
//             const productId = button.getAttribute('data-product-id');
//             const productDialog = document.getElementById(`product-dialog-${productId}`);
//             productDialog.showModal();
//             currentOpenDialog = productDialog;
//         });
//     });

//     // Event listener to close the dialog when clicked outside
//     document.addEventListener('click', (event) => {
//         if (currentOpenDialog && event.target === currentOpenDialog) {
//             currentOpenDialog.close();
//             currentOpenDialog = null;
//         }
//     });
// });

/* WOOCOMMERCE SORTING */
document.addEventListener('DOMContentLoaded', function() {
    const checkboxes = document.querySelectorAll('.woocommerce-sorting input[type="checkbox"]');

    checkboxes.forEach(function(checkbox) {
        checkbox.addEventListener('change', function() {
            if (this.checked) {
                checkboxes.forEach(function(innerCheckbox) {
                    if (innerCheckbox !== checkbox) {
                        innerCheckbox.checked = false;
                    }
                });
            }
        });
    });
});

/* WOOCOMMERCE PRODUCT DESCRIPTION */
document.addEventListener('DOMContentLoaded', function() {
    var toggleButtons = document.querySelectorAll('.toggle-description');

    for (let i = 0; i < toggleButtons.length; i++) {
        toggleButtons[i].addEventListener('click', function(e) {
            e.preventDefault();
            var fullDescription = this.previousElementSibling;

            if (fullDescription.style.display === 'none' || fullDescription.style.display === '') {
                fullDescription.style.display = 'block';
                this.textContent = '-';
            } else {
                fullDescription.style.display = 'none';
                this.textContent = '+';
            }
        });
    }
});

/* WOOCOMMERCE PRODUCT SHARE */
document.addEventListener("DOMContentLoaded", function() {
    var shareButton = document.querySelector('.product-icons__share');
    var shareIcons = document.querySelector('.product-icons__share__icons');
    var productIcons = document.querySelector('.product-icons');

    if(shareButton) {
        shareButton.addEventListener('click', function(e) {
            e.preventDefault();
            productIcons.classList.toggle('slide-out');
        });
    }
});

/* SHOWROOM POINTS */
document.addEventListener('DOMContentLoaded', function() {
    const points = document.querySelectorAll('.showroom__points__item');
    let currentOpenDialog = null; 

    points.forEach(point => {
        point.addEventListener('click', function(event) {
            event.stopPropagation();
            const dialogId = point.getAttribute('data-dialog-id');
            const dialog = document.getElementById(dialogId);
            if (dialog) {
                dialog.showModal();
                currentOpenDialog = dialog;
            }
        });
    });

    document.addEventListener('click', function(event) {
        if (currentOpenDialog && event.target === currentOpenDialog) {
            currentOpenDialog.close();
            currentOpenDialog = null;
        }
    });
});

/* SORTING & FILTERS MOBILE */
document.addEventListener("DOMContentLoaded", function() {
    var filterButton = document.querySelector('.filter-toggle');
    var woocommerceSidebar = document.querySelector('.woocommerce-shop__top');
  
    if(filterButton) {
        filterButton.addEventListener('click', function(event) {
            event.preventDefault();
            woocommerceSidebar.classList.toggle('show');
        }); 
    }
  
    var closeSidebarButton = document.querySelector('.woocommerce-shop__top__close');
  
    if(closeSidebarButton) {
        closeSidebarButton.addEventListener('click', function(event) {
            event.preventDefault();
            woocommerceSidebar.classList.remove('show');
        });
    }
});

  
document.addEventListener('DOMContentLoaded', function() {
    const priceElement = document.querySelector('.e-design .price .woocommerce-Price-amount');
    const optionsPriceElement = document.querySelector('.e-design .wcpa_price_summary .wcpa_options_total .price_value');

    function updateMainPrice() {
        const optionsPrice = optionsPriceElement.textContent;
        if (priceElement && optionsPrice) {
            priceElement.innerHTML = `<bdi><span class="woocommerce-Price-currencySymbol">£</span>${optionsPrice}</bdi>`;
        }
    }
    const observer = new MutationObserver(function(mutations) {
        mutations.forEach(function(mutation) {
            if (mutation.type === "characterData" || mutation.type === "childList") {
                updateMainPrice();
            }
        });
    });

    observer.observe(optionsPriceElement, {
        characterData: true,
        childList: true, 
        subtree: true,
    });

    updateMainPrice();
});

document.addEventListener('DOMContentLoaded', function() {
    const searchButtons = document.querySelectorAll('.search-btn');
    const searchInput = document.querySelector('.header__search > .container > .searchform > .searchform__input input[type="search"]');

    searchButtons.forEach(function(searchButton) {
        searchButton.addEventListener('click', function() {
            document.querySelector('.header__search').classList.toggle('active');
            searchInput.focus();
        });
    });
});

document.addEventListener('DOMContentLoaded', function() {
    const searchCloseButton = document.querySelector('#search-close');

    if (searchCloseButton) {
        searchCloseButton.addEventListener('click', function() {
            document.querySelector('.header__search').classList.remove('active');
        });
    }
});



/* ====================== PRODUCT VARIATIONS ======================  */

jQuery(document).ready(function($) {
    $('.variation-option').on('click', function(e) {
        e.preventDefault();
    
        // Remove active class from all buttons
        $('.variation-option').removeClass('active');
    
        // Add active class to the clicked button
        $(this).addClass('active');
    
        // Extract data attributes from the button
        let attributes = {};
        $.each(this.attributes, function() {
            if(this.specified && this.name.startsWith('data-attribute_')) {
                let attrName = this.name.replace('data-', '');
                attributes[attrName] = this.value;
            }
        });
    
        // Find matching variation
        let matchingVariation = product_variations.find(variation => {
            for(let attr in attributes) {
                if (variation.attributes[attr] !== attributes[attr]) {
                    return false;
                }
            }
            return true;
        });
    
        if (matchingVariation) {
            $('.variations-grid__heading span').text([matchingVariation.attributes.attribute_pa_colour]);
    
            $('input[name=variation_id]').val(matchingVariation.variation_id);
            
            // Trigger the change event for the variation_id input
            $('input[name=variation_id]').trigger('change');
            
            // Trigger the found_variation event on the form & Updates Image
            $('.variations_form').trigger('found_variation', [matchingVariation]);
            
            // Also trigger the WooCommerce events to check variations
            $('.variations_form').trigger('woocommerce_variation_has_changed');
        } else {
            console.error('No matching variation found');
        }
    });

    $( ".nav-toggle").on('click', function() {
        $('.header').toggleClass('nav--nav-open');
        $(this).toggleClass('nav-toggle--open');
        $("html, body").toggleClass("noscroll");
    });

    $('.bapf_body label').each(function() {
        // Get the current label text
        var label_text = $(this).text();
        
        // Remove 'Sort by ' from the label text
        var new_label_text = label_text.replace('Sort by ', '');

        // Capitalize the first letter
        new_label_text = new_label_text.charAt(0).toUpperCase() + new_label_text.slice(1);
        
        // Update the label text
        $(this).text(new_label_text);
    });

    let currentOpenDialog = null;

    $(document).on('click', '.product-expand', function(event) {
        event.stopPropagation();
        const productId = $(this).data('product-id');
        const productDialog = $(`#product-dialog-${productId}`)[0];
        productDialog.showModal();
        currentOpenDialog = productDialog;
    });

    $(document).on('click', function(event) {
        if (currentOpenDialog && event.target === currentOpenDialog) {
            currentOpenDialog.close();
            currentOpenDialog = null;
        }
    });

});


jQuery(document).ready(function($) {
    var $headerBottom = $('.header__bottom');
    var stickyOffset = $headerBottom.offset().top;

    $(window).scroll(function() {
        if ($(window).scrollTop() >= stickyOffset) {
            $headerBottom.addClass('sticky');
        } else {
            $headerBottom.removeClass('sticky');
        }
    });
});


/* ====================== PRODUCT ADD TO CART AJAX ======================  */

jQuery(document).ready(function($) {
    $('.product-dialog__add').on('click', function(e) {
        e.preventDefault();

        let product_id = $(this).data('product-id');

        $.ajax({
            type: 'POST',
            url: custom_ajax_object.ajax_url,
            data: {
                action: 'add_product_to_cart',
                product_id: product_id
            },
            success: function(response) {
                if (response.success) {
                    
                    if ($('.count').length) { 
                        $('.count span').text(response.data.cart_count);
                    } else { 
                        $('<div class="count"><span>' + response.data.cart_count + '</span></div>').insertAfter('a[href*="cart"] span');
                    }

                    alert('Product added to cart!');
                } else {
                    alert('Something went wrong!');
                }
            }
        });
    });
});

document.addEventListener('DOMContentLoaded', function() {
    const checkbox = document.querySelector('#checkbox-group-1709907821449_2_0');
    const imageGridCheckboxes = document.querySelectorAll('#wcpa-image-group-1709813411486 input[type="checkbox"]');
    const priceValueSpan = document.querySelector('.wcpa_priceouter .price_value');
    const displayedPriceElement = document.querySelector('.e-design .price .woocommerce-Price-amount');
    const selectedOptionsInput = document.querySelector('#selected_options_count');
    const checkboxInput = document.querySelector('#increase_price_checkbox_value');
    const totalSelectionInput = document.querySelector('#total_selection_value');

    function parsePrice(textContent) {
        return parseFloat(textContent.replace(/[^0-9.-]+/g, ""));
    }

    const updateValues = () => {
        let basePrice = parsePrice(priceValueSpan.textContent);
        let additionalCharge = 0;
        let selectedCount = 0;

        imageGridCheckboxes.forEach((checkbox) => {
            if (checkbox.checked) {
                selectedCount++;
            }
        });

        if (checkbox.checked) {
            additionalCharge = 100 * selectedCount; // Adds £100 for each selected item
            checkboxInput.value = 'yes';
        } else {
            checkboxInput.value = 'no';
        }

        let totalPrice = basePrice + additionalCharge;
        selectedOptionsInput.value = selectedCount;
        totalSelectionInput.value = totalPrice.toFixed(2);

        if (displayedPriceElement) {
            displayedPriceElement.innerHTML = `<bdi><span class="woocommerce-Price-currencySymbol">£</span>${totalPrice.toFixed(2)}</bdi>`;
        }
    };

    checkbox.addEventListener('change', updateValues);
    imageGridCheckboxes.forEach(checkbox => {
        checkbox.addEventListener('change', updateValues);
    });

    const observer = new MutationObserver(updateValues);
    observer.observe(priceValueSpan, { childList: true, subtree: true, characterData: true });

    updateValues();
});

// document.addEventListener('DOMContentLoaded', function() {
//     var label = document.querySelector('.variations__row > label[for="pa_size"]');
//     var label2 = document.querySelector('#wcpa-image-group-1708014345810 > label[for="image-group-1708014345810"]');

//     if (label) {
//         label.textContent = '1. Select a Size';
//     }

//     if (label2) {
//         label2.textContent = '2. Select a Fabric';
//     }
// });
